/* ======================================================
   <!-- Multiple-Level Dropdown Menu -->
/* ====================================================== */

.vertical-menu {

	margin: 0;
	padding: 0;

	.vertical-menu__arrow {
        transition: .1s ease-in-out;
		display: inline-block;
		width: 1rem;
		height: 1rem;
		text-align: center;
		position: absolute;
		right: 1rem;
		top: .25rem;

		&::before {
			display: inline-block;
			content: "+";
			font-family: 'arial';
			font-weight: 600;
			color: #333;
			cursor: pointer;
		}

	}

	li {
		margin: 0;
		padding: 0;
		width: 100%;
		position: relative;
		padding: .5rem 1rem;

		> a {
			display: block;
			border-bottom: 1px solid #e5e5e5;

			&:hover {
				border-color: #333;

			}

			&.is-active {

				 > .vertical-menu__arrow {
					transform: rotate(90deg);
					top: .5rem;
				}


			}

		}

        &.is-active > a {
            color: #000;
        }        


	}

	> li {

		ul {
            transition: max-height 0.25s ease;
			overflow: hidden;
			position: relative;
		}
	}

	> li:not(.is-active) {

		ul {
			max-height: 0;
		}
	}

	> li ul ul {
        margin-top: .5rem;
	}

}
