/* ======================================================
   <!-- Back to Top -->
/* ====================================================== */

.back-to-top {
	button {
		transform: scale(0);
		position: fixed;
		bottom: 12px;
		right: 12px;
		width: 50px;
		height: 50px;
		z-index: 99;
		text-align: center;
        transition: .1s ease-in-out;

		display: block;
		outline: none;
		border: none;
		background-color: transparent;
        cursor: pointer;

		&.is-active {
			transform: scale(1);
		}

		&:active,
		&:focus {
			outline: none;
		}	

		i {
			font-size: 1.5rem;
		}	
	}

}
