.button {
	background-color: #c4e4db;
	color: #0576a0;
	text-decoration: none;
	padding: .6em 1.7em;
	display: inline-block;
	min-width: 200px;
	max-width: 100%;
	font-size: 0.75em;
	border-radius: 4px;
	margin-right: 1.3em;
	text-align: center;

	&:hover {
	  background-color: #fff;
	}
  }